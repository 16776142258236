<template>
  <div style="margin: 10% 5% 10% 5%">
    <v-layout row wrap justify-center>
      <v-flex class="header_text" xs12 md12 lg12 sm12 text-center>
        <div data-aos="fade-right">
          <h1
            style="
              margin-bottom: 1% !important;
              text-align: center;
              color: #000000 !important;
              font-weight: 800;
            "
          >
            Solid Paper Board Packaging Center
          </h1>
        </div>
      </v-flex>
      <v-flex xs12 sm12 md12 lg12 xl12 text-center>
        <v-card color="blue lighten-4" class="mx-1">
          <div class="gov" data-aos="fade-right">
            <p class="mt-2">
              Solid Paper Board Packaging Project: The Solid Paper Board
              Packaging is a project implemented by SUS own fund. Earlier it was
              funded by the Australian Agency for International Development
              (AUS/AID).<br /><br />
              This project is being implemented in Savar Upazila of Dhaka
              district with the profit earned through sale proceeds. The main
              objective of this project is to create a resource base of the
              organization for its sustainability and also create job
              opportunity for the poor and distressed women group members.<br /><br />
              The activities of this project are: making sweetmeat, sharees
              (women wear) and foot wear boxes by solid paperboard and selling
              these boxes to different shops at and around Savar and Dhaka City.
            </p>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.gov h4 {
  font-family: Century Gothic;

  font-size: 1.8em;
  color: #000000;
  text-align: center;
  margin: 5% 3% 5% 3%;
}

.gov p {
  font-size: 0.9em !important;
  font-family: Century Gothic;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 20px;
  color: #020202;
  text-align: justify;
  margin: 0% 5% 0% 5%;
  padding: 2%;
}
</style>
